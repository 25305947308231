import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/mobileView/home/HomeView.vue'
import AppDownload from '@/views/mobileView/appDownload/appDownload.vue'
import ProductCenter from '@/views/mobileView/productCenter/productCenter.vue'
import businessCooperation from '@/views/mobileView/businessCooperation/businessCooperation.vue'
import caseCenter from '@/views/mobileView/caseCenter/caseCenter.vue'
import aboutUs from '@/views/mobileView/aboutUs/aboutUs.vue'
import serviceSupport from '@/views/mobileView/serviceSupport/serviceSupport.vue'
import qingChuSwitch from '@/views/mobileView/productCenter/components/details-page/switch/switch_1.vue'
// 双开轻触
import shuangKaiQingChuSwitch from '@/views/mobileView/productCenter/components/details-page/switch/switch_2.vue'

// c7
import c7Screen from '@/views/mobileView/productCenter/components/details-page/screen/c7.vue'
import x8Screen from '@/views/PcView/productCenter/components/details-page/screen/x8.vue'
import s10Screen from '@/views/mobileView/productCenter/components/details-page/screen/s10.vue'
import m10Screen from '@/views/PcView/productCenter/components/details-page/screen/m10.vue'
import lamp from '@/views/PcView/productCenter/components/details-page/lamp/lamp.vue'
import downLight from '@/views/PcView/productCenter/components/details-page/down-light/downLight.vue'
import {load} from "@/utils/loading_mb";
import customMeda from "@/views/PcView/home/components/custom-made.vue";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/app-download',
    name: 'appDownload',
    component: AppDownload
  },
  {
    path: '/product-center',
    name: 'productCenterMb',
    component: ProductCenter
  },
  {
    path: '/businessCooperation',
    name: 'businessCooperation',
    component: businessCooperation,
  },
  {
    path: '/caseCenter',
    name: 'caseCenter',
    component: caseCenter,
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs,
  },
  {
    path: '/serviceSupport',
    name: 'serviceSupport',
    component: serviceSupport,
  },
  {
    path: '/switch_1',
    name: 'qingchuSwitch',
    component: qingChuSwitch,
  },
  {
    path: '/switch_2',
    name: 'shuangKaiQingChuSwitch',
    component: shuangKaiQingChuSwitch,
  },
  {
    path: '/c7_screen',
    name: 'c7Screen',
    component: c7Screen,
  },
  {
    path: '/x8_screen',
    name: 'x8Screen',
    component: x8Screen,
  },
  {
    path: '/s10_screen',
    name: 's10Screen',
    component: s10Screen,
  },
  {
    path: '/m10_screen',
    name: 'm10Screen',
    component: m10Screen,
  },
  {
    path: '/custom-meda',
    name: 'customMeda',
    component: customMeda,
  },
  {
    path: '/lamp',
    name: 'lamp',
    component: lamp,
  },
  {
    path: '/down-light',
    name: 'downLight',
    component: downLight,
  },
]

const routerMb = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
routerMb.beforeEach((to, from, next) => {
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  load.show();
  next()
  // load.hide();
})

routerMb.afterEach((to, form) => {
  load.hide();
})

export default routerMb
