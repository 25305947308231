import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css';
import HomeView from '@/views/PcView/home/HomeView.vue'
import customMeda from '@/views/PcView/home/components/custom-made.vue'
import productCenter from '@/views/PcView/productCenter/productCenter.vue'
import businessCooperation from '@/views/PcView/businessCooperation/businessCooperation.vue'
import businessCooperationProject from '@/views/PcView/businessCooperation/businessCooperation_project.vue'
import caseCenter from '@/views/PcView/caseCenter/caseCenter.vue'
import serviceSupport from '@/views/PcView/serviceSupport/serviceSupport.vue'
import aboutUs from '@/views/PcView/aboutUs/aboutUs.vue'
import productDetails from '@/views/PcView/productCenter/productDetails.vue'
import appDownload from '@/views/PcView/appDownload/appDownload.vue'
import qingChuSwitch from '@/views/PcView/productCenter/components/details-page/switch/qingchu_switch.vue'
// 双开轻触
import shuangKaiQingChuSwitch from '@/views/PcView/productCenter/components/details-page/switch/sankai_qingchu_switch.vue'

// c7
import c7Screen from '@/views/PcView/productCenter/components/details-page/screen/c7.vue'
import x8Screen from '@/views/PcView/productCenter/components/details-page/screen/x8.vue'
import s10Screen from '@/views/PcView/productCenter/components/details-page/screen/s10.vue'
import m10Screen from '@/views/PcView/productCenter/components/details-page/screen/m10.vue'
import lamp from '@/views/PcView/productCenter/components/details-page/lamp/lamp.vue'
import downLight from '@/views/PcView/productCenter/components/details-page/down-light/downLight.vue'
// router/index.ts
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    component: productCenter,
  },
  {
    path: '/businessCooperation',
    name: 'businessCooperation',
    component: businessCooperation,
  },
  {
    path: '/businessCooperation_project',
    name: 'businessCooperationProject',
    component: businessCooperationProject,
  },
  {
    path: '/caseCenter',
    name: 'caseCenter',
    component: caseCenter,
  },
  {
    path: '/serviceSupport',
    name: 'serviceSupport',
    component: serviceSupport,
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs,
  },
  {
    path: '/product-details/:id',
    name: 'productDetails',
    component: productDetails,
  },
  {
    path: '/app-download',
    name: 'appDownload',
    component: appDownload,
  },
  {
    path: '/switch_1',
    name: 'qingchuSwitch',
    component: qingChuSwitch,
  },
  {
    path: '/switch_2',
    name: 'shuangKaiQingChuSwitch',
    component: shuangKaiQingChuSwitch,
  },
  {
    path: '/c7_screen',
    name: 'c7Screen',
    component: c7Screen,
  },
  {
    path: '/x8_screen',
    name: 'x8Screen',
    component: x8Screen,
  },
  {
    path: '/s10_screen',
    name: 's10Screen',
    component: s10Screen,
  },
  {
    path: '/m10_screen',
    name: 'm10Screen',
    component: m10Screen,
  },
  {
    path: '/custom-meda',
    name: 'customMeda',
    component: customMeda,
  },
  {
    path: '/lamp',
    name: 'lamp',
    component: lamp,
  },
  {
    path: '/down-light',
    name: 'downLight',
    component: downLight,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  // NProgress.start()
  next()
});

router.afterEach(() => {
  // NProgress.done()
})



export default router
