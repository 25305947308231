import * as CryptoTS from 'crypto-ts';
import {CipherParams} from "crypto-ts/src/lib/CipherParams";
import {WordArray} from "crypto-ts/src/lib/WordArray";
export default {
    encrypt (word: any) { // 加密
        const secretKey = '307977E7203B9F1744C145B1F82217CD'
        const key: WordArray = CryptoTS.enc.Utf8.parse(secretKey)
        const srcs: WordArray = CryptoTS.enc.Utf8.parse(word)
        const encrypted: CipherParams = CryptoTS.AES.encrypt(srcs, key, { mode: CryptoTS.mode.ECB, padding: CryptoTS.pad.PKCS7}) // 加密模式为ECB，补码方式为PKCS5Padding（也就是PKCS7）
        return encrypted.toString()
    },
}
