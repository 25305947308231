import { createApp } from 'vue'
import App from './App.vue'
import APPMB from './App_mb.vue'
import router from './router/index'
import routerMb from './router/index_mb'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/globalCss/globall.less'
import 'amfe-flexible'
import "animate.css/animate.min.css";
import '@/assets/icon/iconfont.css'
const isMobile = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
const app = isMobile ? createApp(APPMB) : createApp(App)
// isMobile ? generateRem(document, window) : ''
// generateRem(document, window);
import imgLazy from '@/utils/lazy'
// const app = createApp(App)
import animate from "@/hooks/animate";

import 'magic-img/css'
// @ts-ignore
import MagicImg from 'magic-img/vue3' // vue3

app.use(MagicImg)
app.use(animate)
app.use(imgLazy);

app.use(ElementPlus)
// 全局注册图片懒加载
app.use(isMobile ? routerMb : router)
app.mount('#app')
