export default (app: any) => {
//注册'v-animate'  当元素出现在可视范围时添加类名触发动效样式
    app.directive('animate', {
        mounted: (el: HTMLElement, binding: any) => {
            const oldElClassName = el.className
            // 聚焦元素
            binding.addClass = () => {
                const { top, bottom } = el.getBoundingClientRect()
                const h = document.documentElement.clientHeight || document.body.clientHeight
                if (top <= h && bottom > 0) {
                    if(el.className.indexOf(binding.value) == -1 ){
                        // 初次还未绑定过，则新增类名(注意：下面单引号中间是一个空格！！！)
                        el.className = binding.value+' '+el.className
                    }
                    if (binding.addClass) {
                        window.removeEventListener('scroll', binding.addClass)
                    }
                } else {
                    el.className = oldElClassName
                }
            }
            window.addEventListener('scroll', binding.addClass,true)
            binding.addClass()
        },
        unmounted: (el: HTMLElement, binding: any) => {
            if (binding.addClass) {
                window.removeEventListener('scroll', binding.addClass)
            }
        }
    })
}

// v-animate="'animate__animated animate__zoomIn'"
