import type {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import axios from 'axios';
import router from '@/router';
import AESZ from "@/http/requestUtils/AE";
import {Md5} from 'ts-md5';

let baseUrl = 'https://www.zhhx.top:18880/'

console.log(window.location.href)
if(window.location.href.includes('https')) {
    baseUrl = 'https://www.zhhx.top:18880/'
} else {
    baseUrl = 'http://dev.zhhx.top:18860'
}

class Request {

    private request(opts: {}) {
        const instance: AxiosInstance = axios.create({
            baseURL: baseUrl,
            timeout: 6000,
        });
        this.setInterceptors(instance)
        return instance(opts);
    }

    // 拦截器
    private setInterceptors(instance: AxiosInstance) {
        // 请求拦截器
        instance.interceptors.request.use((config) => {
            const data = config.data === undefined ? {} : config.data;
            config.data = {};
            config.data.data = data
            config.data.deviceType = getOS()
            const encData: string = AESZ.encrypt(JSON.stringify(data))
            config.data.encData = encData
            config.data.reqTime = new Date().getTime()
            config.data.version = '1.0.0'
            config.data.token = 'no token'
            const token: string = 'no token'
            const sign: string = doSign(
                token,
                config.data.reqTime,
                config.data.encData,
                config.data.deviceType,
                config.data.version
            )
            config.data.sign = sign

            return config;
        });
        // 响应拦截器
        instance.interceptors.response.use(
            (res: AxiosResponse) => {
                const {code, data} = res.data;
                if (res.status === 200) {
                    return Promise.resolve(res.data);
                }
                switch (code) {
                    case -100:
                        setTimeout(() => {
                            router.push('/login');
                        }, 1000);
                        break;
                    case -1:
                        break;
                }
                return Promise.reject(res.data);
            },
            (err) => {
                // switch (err.response.status) {
                //   case 401:
                //     setTimeout(() => {
                //       router.push('/login');
                //     }, 1000);
                //     break;
                //     case 400:
                //         Message.error('错误请求')
                //         break;
                //   default:
                //     console.warn(`status= `);
                //     break;
                // }
                return Promise.reject(err);
            }
        );
    }

    // 封装get请求
    public GET(url: string, options?: AxiosRequestConfig) {
        return this.request({
            url,
            method: 'get',
            params: options,
        });
    }

    // 封装post请求
    public POST(url: string, options: AxiosRequestConfig) {
        return this.request({
            url,
            method: 'post',
            headers: {
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: options,
        });
    }
}

function getOS() {
    const sUserAgent: string = navigator.userAgent
    const isWin: boolean = navigator.platform === 'Win32' || navigator.platform === 'Windows'
    const isMac: boolean =
        navigator.platform === 'Mac68K' ||
        navigator.platform === 'MacPPC' ||
        navigator.platform === 'Macintosh' ||
        navigator.platform === 'MacIntel'
    if (isMac) return 'Mac'
    const isUnix: boolean = navigator.platform === 'X11' && !isWin && !isMac
    if (isUnix) return 'Unix'
    const isLinux: boolean = String(navigator.platform).indexOf('Linux') > -1
    if (isLinux) return 'Linux'
    if (isWin) {
        const isWin2K: boolean = sUserAgent.indexOf('Windows NT 5.0') > -1 || sUserAgent.indexOf('Windows 2000') > -1
        if (isWin2K) return 'Win2000'
        const isWinXP: boolean = sUserAgent.indexOf('Windows NT 5.1') > -1 || sUserAgent.indexOf('Windows XP') > -1
        if (isWinXP) return 'WinXP'
        const isWin2003: boolean = sUserAgent.indexOf('Windows NT 5.2') > -1 || sUserAgent.indexOf('Windows 2003') > -1
        if (isWin2003) return 'Win2003'
        const isWinVista: boolean = sUserAgent.indexOf('Windows NT 6.0') > -1 || sUserAgent.indexOf('Windows Vista') > -1
        if (isWinVista) return 'WinVista'
        const isWin7: boolean = sUserAgent.indexOf('Windows NT 6.1') > -1 || sUserAgent.indexOf('Windows 7') > -1
        if (isWin7) return 'Win7'
        const isWin10: boolean = sUserAgent.indexOf('Windows NT 10') > -1 || sUserAgent.indexOf('Windows 10') > -1
        if (isWin10) return 'Win10'
    }
    return 'other'
}

function doSign(token: string, reqTime: string, encData: string, deviceType: string, version: string) {
    if (token === undefined) token = ''
    if (reqTime === undefined) reqTime = ''
    if (encData === undefined) encData = ''
    if (deviceType === undefined) deviceType = ''
    if (version === undefined) version = ''
    token = token + reqTime + encData + deviceType + version
    token = token + '@;&'
    // MD5加密
    return Md5.hashStr(token)
}

export const httpRequest = new Request();
