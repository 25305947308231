
// @ts-ignore
import defaltImg from '../assets/default.png'
// 引入监听是否进入视口
import { useIntersectionObserver } from '@vueuse/core'

export default{
  install(app: any){
    // 自定义指令
    app.directive('imglazy',{
      mounted(el: any,binding: any){
        // el:  dom对象
        // binding:binding.value可以拿到图片的url地址
        const { stop } = useIntersectionObserver(
          //监听目标元素
          el,
          ([{ isIntersecting }], observerElement) => {
            if(isIntersecting){
              // 当图片加载失败的时候就用默认的图片代替
              el.onerror=()=>{
                el.src = defaltImg
              }
              el.src = binding.value
              stop()
            }
          },
          // 刚进入视口区域就立刻执行回调 0 - 1
          { threshold: 0 }
        )
      }
    })
  }
}